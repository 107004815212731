<template>
  <c-flex
    flex="1"
    flex-direction="column"
    justify-content="center"
    align-items="center"
    my="60px"
  >
    <c-image
      v-if="$route.name === 'content-challenge.coaching-course'"
      :h="['189px', '300px']"
      :src="require('@/assets/icon-empty-coaching-course.svg')"
      alt="empty coaching course illustration"
    />
    <c-image
      v-if="$route.name !== 'content-challenge.coaching-course'"
      :h="['189px', '300px']"
      :src="require('@/assets/img-cart-empty.svg')"
      alt="empty content illustration"
    />
    <c-text
      mt="41px"
      color="gray.900"
      :fontSize="['14px', '24px']"
      :lineHeight="['21px', '36px']"
      textAlign="center"
      v-html="label"
    />
    <c-button
      v-if="$route.name !== 'content-challenge.coaching-course'"
      variant="outline"
      border-radius="25px"
      variant-color="brand"
      fontSize="16px"
      fontWeight="bold"
      right-icon="arrow-forward"
      mt="35px"
      py="13px"
      px="28px"
      h="50px"
      as="router-link"
      to="/#program"
    >
      Beli program
    </c-button>
  </c-flex>
</template>

<script>
export default {
  name: "ContentChallengeEmptyPage",
  props: ["label"],
};
</script>
